<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Answer</h2>
    </div>
    <v-form
        ref="form"
        v-model="fValid"
        lazy-validation
    >
      <v-text-field
          v-model="name"
          :rules="requiredRules"
          label="Name"
          required
      ></v-text-field>
      <v-textarea
          label="Description"
          :rules="requiredRules"
          v-model="description"
      ></v-textarea>
      <v-text-field
          v-model="index"
          :rules="numberRules"
          label="Index"
          required
      ></v-text-field>
      <div>
        <label class="v-label theme--light">Question</label>
        <v-select
            :reduce="item => item._id"
            :options="questions"
            v-model="question"
            :getOptionLabel="item => item.name"
        ></v-select>
      </div>
      <v-switch
          v-model="valid"
          :label="`${valid ? 'Valid': 'Invalid'}`"
      ></v-switch>
      <v-btn
          :disabled="!fValid"
          :loading="isLoading"
          color="success"
          class="mr-4"
          @click="submit"
      >
        Submit
      </v-btn>
    </v-form>
  </div>
</template>


<script>
  import vSelect from 'vue-select'
  export default {
    name: 'Answer',
    components: {
      vSelect
    },
    data() {
      return {
        isLoading: false,
        fValid: true,
        name: '',
        description: '',
        index: 0,
        valid: false,
        question: '',
        questions: [],
        requiredRules: [
          v => !!v || 'Field is required',
        ],
        numberRules: [
          v => !!v || v === 0 || 'Field is required',
        ],
      }
    },
    mounted() {
      this.getAnswer();
      this.getQuestions();
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          let url = '/answers'
          let id = this.$route.params.id && this.$route.params.id !== 'create' ? this.$route.params.id : null;
          if (id) {
            url += '/' + id;
          }
          let data = {
            name: this.name,
            description: this.description,
            index: this.index,
            valid: this.valid,
          }
          if (this.image) {
            data.image = this.image;
          }
          if (this.question) {
            data.question = this.question;
          }
          this.$axios[id ? 'put' : 'post'](url, data).then((res) => {
            this.$root.notify({color: 'success', text: 'Success created'})

            this.$router.push("/answers")
          }).catch(err => {
            this.isLoading = false;
            this.$root.notify({color: 'error', text: err.error ? err.error : "Unknown Error!"})
          });
        } else {
          this.$root.notify({color: 'error', text: 'Fill all fields'})
        }
      },
      getAnswer() {
        let id = this.$route.params.id;
        if (id && id !== 'create') {
          let url = '/answers/' + id;
          this.$axios.get(url).then(res => {
            this.name = res.data.name;
            this.description = res.data.description;
            this.valid = res.data.valid;
            this.index = res.data.index;
            this.question = res.data.question && res.data.question._id ? res.data.question._id : res.data.question;
          }).catch(e => {
            console.log(e)
          });
        }
      },
      getQuestions() {
        let url = '/questions';
        this.$axios.get(url).then(res => {
          this.questions = res.data;
        }).catch(e => {
          console.log(e)
        });
      }
    },
    watch: {

    },
    computed: {

    }
  }

</script>

